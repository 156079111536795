
import { Component, Prop, Vue } from 'vue-property-decorator';

import ChevronDoubleDown from '@/assets/icons/ChevronDoubleDown.svg';

import Pill from '@/components/shared/Pill.vue';

import { SaveGradeConfirmData, DeliveryStatusColor, DeliveryStatusText } from '@/models';

@Component({
  name: 'SaveConfirm',
  components: {
    ChevronDoubleDown,
    Pill,
  },
})
export default class SaveConfirm extends Vue {
  @Prop() data!: SaveGradeConfirmData

  get fromColor(): DeliveryStatusColor {
    return DeliveryStatusColor[this.data.from];
  }

  get fromText(): DeliveryStatusText {
    return DeliveryStatusText[this.data.from];
  }

  get toColor(): DeliveryStatusColor {
    return DeliveryStatusColor[this.data.to];
  }

  get toText(): DeliveryStatusText {
    return DeliveryStatusText[this.data.to];
  }
}
