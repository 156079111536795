
import {
  Component,
  Prop,
  Vue,
  Model,
} from 'vue-property-decorator';

import Close from '@/assets/icons/Close.svg';

import BigDashedButton from '@/components/shared/BigDashedButton.vue';
import ToggleSwitch from '@/components/shared/ToggleSwitch.vue';
import TextEditor from '@/components/shared/TextEditor.vue';

import { DynamicTextareaModel, DynamicTextareaItemModel } from '@/models';

@Component({
  name: 'DynamicTextare',
  components: {
    Close,
    BigDashedButton,
    ToggleSwitch,
    TextEditor,
  },
})

export default class DynamicTextarea extends Vue {
  @Model('change') data: DynamicTextareaModel;
  @Prop({ default: false }) disabled: boolean;

  public addItem() {
    const newItem: DynamicTextareaItemModel = { id: 0, value: '', required: false };
    this.data.list.push(newItem);

    this.$emit('change', this.data);
  }

  public removeItem(idx: number) {
    this.data.removedItems.push(this.data.list[idx].id);
    this.data.list.splice(idx, 1);
    this.data.list[0].required = true;

    this.$emit('change', this.data);
  }

  public toggleRequired(indx: number, value: boolean) {
    if (this.disabled) return;

    this.data.list[indx].required = !value;

    this.$emit('change', this.data);
  }

  public saveModifications() {
    this.$emit('change', this.data);
  }
}
