
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'Tooltip',
})

export default class Tooltip extends Vue {
  @Prop({ default: '' }) text: string;
  @Prop({ default: false }) noShow: boolean;

  private debounce: number;
  public tooltipClass: string;
  public hide = true;

  public showTooltip() {
    if (this.noShow) return;

    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => this.hide = false, 500);
  }

  public hideTooltip() {
    clearTimeout(this.debounce);
    this.hide = true;
  }
}
