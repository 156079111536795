
import { Component, Mixins } from 'vue-property-decorator';
import { VueConstructor } from 'vue';
import { Route, NavigationGuardNext } from 'vue-router';
import { mapActions, mapGetters } from 'vuex';
import { AxiosError } from 'axios';

import PublicationModalFlow from '@/mixins/PublicationModalFlow';

import Header from '@/components/Header/Header.vue';
import Toolbar from '@/components/shared/Toolbar.vue';
import AssignmentSection from '@/components/Assignment/AssignmentSection.vue';
import AttachmentBox from '@/components/shared/AttachmentBox.vue';
import VideoSearch from '@/components/shared/VideoSearch.vue';
import Keywords from '@/components/shared/Keywords.vue';
import TextEditor from '@/components/shared/TextEditor.vue';
import DynamicTextarea from '@/components/shared/DynamicTextarea.vue';
import SearchInput from '@/components/shared/SearchInput.vue';
import DynamicTable from '@/components/DynamicTable/DynamicTable.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';

import PublishConfirm from '@/components/AssignmentObject/PublishConfirm.vue';
import EditConfirm from '@/components/AssignmentObject/EditConfirm.vue';
import SaveDraftConfirm from '@/components/AssignmentObject/SaveDraftConfirm.vue';
import LeavePageConfirm from '@/components/AssignmentObject/LeavePageConfirm.vue';

import { ModalService, DynamicTableService } from '@/services';

import {
  DTable,
  SuggestorType,
  DTableBody,
  IconsList,
  ChipType,
  ModalTipos,
  DataConfirmation,
  AssignmentObjectModel,
  Course,
  RouteName,
  Class,
  DynamicTextareaModel,
  DynamicTextareaItemModel,
  QuestionModel,
  FileUpload,
  SearchDisciplineParams,
  ClassesGetParams,
  AssignmentObjectEvents,
  DTableHeaderBody,
} from '@/models';
import { EventBus } from '@/utils';
import { Discipline } from '@/models/discipline';
import { AssignmentObjectState } from '@/models/store';
import store from '@/store';

const { QUESTION } = IconsList;
const { TURMAS } = DTableBody;
const { SIMPLE_HEADER_TYPE } = DTableHeaderBody;
const { INVALID_DATE } = AssignmentObjectEvents;

const { ASSIGNMENT_OBJECT_MANAGE } = RouteName;

@Component({
  name: 'AssignmentObject',
  components: {
    Header,
    Toolbar,
    AssignmentSection,
    AttachmentBox,
    Keywords,
    VideoSearch,
    TextEditor,
    DynamicTextarea,
    SearchInput,
    DynamicTable,
    CheckboxInput,
    PublishConfirm,
    EditConfirm,
    SaveDraftConfirm,
    LeavePageConfirm,
  },
  computed: {
    ...mapGetters('assignmentObject', {
      canBePublished: 'canBePublished',
      editMode: 'editMode',
      getClasses: 'getClasses',
      getCourseAsSuggestorType: 'getCourseAsSuggestorType',
      getCourseId: 'getCourseId',
      getDisciplineAsSuggestorType: 'getDisciplineAsSuggestorType',
      getDisciplineId: 'getDisciplineId',
      getFilesRequired: 'getFilesRequired',
      getFiles: 'getFiles',
      getKeywordsAsChipType: 'getKeywordsAsChipType',
      getObject: 'getObject',
      getQuestions: 'getQuestions',
      getStatement: 'getStatement',
      getVideoRequired: 'getVideoRequired',
      getVideo: 'getVideo',
      hasChange: 'hasChange',
      isCourseSet: 'isCourseSet',
      isDisciplineSet: 'isDisciplineSet',
      isDraft: 'isDraft',
    }),
    ...mapGetters('classStore', {
      classList: 'classList',
    }),
    ...mapGetters('course', {
      courseListAsSuggestorTypeList: 'courseListAsSuggestorTypeList',
    }),
    ...mapGetters('discipline', {
      disciplineListAsSuggestorTypeList: 'disciplineListAsSuggestorTypeList',
    }),
  },
  methods: {
    ...mapActions('assignmentObject', {
      saveAssignmentObject: 'saveAssignmentObject',
      setClasses: 'setClasses',
      setCourseFromSuggestorType: 'setCourseFromSuggestorType',
      setDisciplineFromSuggestorType: 'setDisciplineFromSuggestorType',
      setFilesRequired: 'setFilesRequired',
      setFiles: 'setFiles',
      setKeywordsFromChipType: 'setKeywordsFromChipType',
      setNewFiles: 'setNewFiles',
      setPublish: 'setPublish',
      setQuestions: 'setQuestions',
      setRemovedQuestions: 'setRemovedQuestions',
      setStatement: 'setStatement',
      setVideoRequired: 'setVideoRequired',
      setVideo: 'setVideo',
    }),
    ...mapActions('classStore', {
      getClassList: 'getClassList',
    }),
    ...mapActions('course', {
      clearCourseList: 'clearCourseList',
      searchCourseList: 'searchCourseList',
    }),
    ...mapActions('discipline', {
      searchDisciplineList: 'searchDisciplineList',
      clearDisciplineList: 'clearDisciplineList',
    }),
  },
  beforeRouteLeave: async (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
    await store.dispatch('assignmentObject/resetAssignmentObject');
    next();
  },
})
export default class AssignmentObject extends Mixins(PublicationModalFlow) {
  public canBePublished: boolean;
  public courseListAsSuggestorTypeList: SuggestorType[];
  public disciplineListAsSuggestorTypeList: SuggestorType[];
  public editMode: boolean;
  public getCourseAsSuggestorType: SuggestorType;
  public getDisciplineAsSuggestorType: SuggestorType;
  public getFilesRequired: boolean;
  public getKeywordsAsChipType: ChipType[];
  public getQuestions: QuestionModel[];
  public getStatement: string;
  public getVideoRequired: boolean;
  public getVideo: string;
  public hasChange: boolean;
  public isCourseSet: boolean;
  public isDisciplineSet: boolean;
  public isDraft: boolean;
  public setDisciplineFromSuggestorType: (suggestor: SuggestorType) => void;
  public setFilesRequired: (fileRequired: boolean) => void;
  public setKeywordsFromChipType: (keywords: ChipType[]) => void;
  public setQuestions: (questions: QuestionModel[]) => void;
  public setStatement: (statement: string) => void;
  public setVideoRequired: (videoRequired: boolean) => void;
  public setVideo: (video: string) => void;

  private classList: Class[];
  private getClasses: Class[];
  private getCourseId: number;
  private getDisciplineId: number;
  private getObject: AssignmentObjectState;
  // TODO: implementar um método próprio para tratar erros
  private window = window;
  private clearCourseList: () => void;
  private clearDisciplineList: () => void;
  private getClassList: (params: ClassesGetParams) => Promise<Class[]>;
  private saveAssignmentObject: (object: AssignmentObjectModel) => Promise<AssignmentObjectModel>;
  private setClasses: (classes: Class[]) => void;
  private setCourseFromSuggestorType: (suggestor: SuggestorType) => void;
  private setFiles: (files: FileUpload[]) => void;
  private setNewFiles: (newFiles: File[]) => void;
  private setPublish: (publish: boolean) => void;
  private setRemovedQuestions: (removedItems: number[]) => void;
  private searchCourseList: (searchKey: string) => Promise<Course[]>;
  private searchDisciplineList: (params: SearchDisciplineParams) => Promise<Discipline[]>;

  public invalidDate = false;
  private goingBack = false;

  public tableData: DTable = {
    header: [{
      hasTooltip: false,
      hasFilter: false,
      width: 1,
      type: SIMPLE_HEADER_TYPE,
    }, {
      text: 'TURMA',
      hasTooltip: false,
      hasFilter: false,
      width: 20,
      grow: false,
      type: SIMPLE_HEADER_TYPE,
    }, {
      text: 'PUBLICAÇÃO',
      hasTooltip: true,
      hasFilter: false,
      tooltipText: 'Data em que a disciplina foi publicada nas turmas',
      tooltipIcon: QUESTION,
      width: 20,
      grow: false,
      type: SIMPLE_HEADER_TYPE,
    }, {
      text: 'DATA ENTREGA\nREGULAR',
      hasTooltip: false,
      hasFilter: false,
      width: 20,
      grow: false,
      type: SIMPLE_HEADER_TYPE,
    }, {
      text: 'DATA ENTREGA\nRECUPERAÇÃO',
      hasTooltip: false,
      hasFilter: false,
      grow: false,
      width: 20,
      type: SIMPLE_HEADER_TYPE,
    }, {
      text: 'NOTA',
      hasTooltip: false,
      hasFilter: false,
      width: 10,
      textAlign: 'right',
      type: SIMPLE_HEADER_TYPE,
    }],
    body: TURMAS,
    data: [],
  };

  get questions(): DynamicTextareaModel {
    const list = this.getQuestions.length
      ? this.getQuestions.map(({ id, question, required }: QuestionModel) => (
        new DynamicTextareaItemModel(id, question, required)
      ))
      : [new DynamicTextareaItemModel()];

    return {
      addButtonText: 'Adicionar questão',
      list,
      placeholder: 'Escreva aqui a questão que deve ser respondida pelos alunos',
      removedItems: [],
      requiredText: 'Questão obrigatória',
      title: 'QUESTÃO',
    };
  }

  set questions({ list, removedItems }: DynamicTextareaModel) {
    const data = list
      .map(({ id, value, required }: DynamicTextareaItemModel) => ({
        id,
        required,
        question: value,
        description: value,
      }));

    this.setQuestions(data);
    this.setRemovedQuestions(removedItems);
  }

  get enablePublish(): boolean {
    return (this.isDraft && this.canBePublished && !this.invalidDate)
      || (this.canBePublished && !this.invalidDate && this.hasChange);
  }

  mounted() {
    this.subscribeToEvents();
  }

  beforeDestroy() {
    this.unsubscribeToEvents();
  }

  private subscribeToEvents() {
    EventBus.$on(INVALID_DATE, this.setInvalidDate);
  }

  private unsubscribeToEvents() {
    EventBus.$off(INVALID_DATE, this.setInvalidDate);
  }

  public getCourseList(courseSearchString: string) {
    if (!courseSearchString.length) {
      this.clearCourseList();
      this.clearDisciplineList();
      this.tableData.data = [];
      return;
    }

    this.searchCourseList(courseSearchString)
      .catch(console.error);
  }

  public getDisciplineList(searchKey: string) {
    if (!searchKey.length) {
      this.clearDisciplineList();
      this.tableData.data = [];
      return;
    }

    const courseId = this.getCourseId;
    this.searchDisciplineList({ courseId, searchKey })
      .catch(console.error);
  }

  public getClassesList() {
    const courseId = this.getCourseId;
    const disciplineId = this.getDisciplineId;

    if (courseId === 0 || disciplineId === 0) {
      this.tableData.data = [];
      return;
    }

    DynamicTableService.requestStarted();

    this.getClassList({ courseId, disciplineId })
      .then(() => { this.populateTableData(); })
      .catch(console.error)
      .finally(() => { DynamicTableService.requestEnded(); });
  }

  private populateTableData() {
    this.tableData.data = [];
    this.tableData.data = this.classList.map((oneClass: Class) => {
      let classToReturn = oneClass;
      const selectedClass = this.getClasses.find((c: Class) => c.id === oneClass.id);

      if (selectedClass) {
        const {
          checked,
          composingGrade,
          deliveryDate,
          hasDeliveryPublished,
          recoveryDeliveryDate,
        } = selectedClass;

        classToReturn = {
          ...classToReturn,
          checked,
          composingGrade,
          deliveryDate,
          hasDeliveryPublished,
          recoveryDeliveryDate,
        };
      }

      return classToReturn;
    });
  }

  public setCourse(course: SuggestorType) {
    this.setCourseFromSuggestorType(course);
    this.clearCourseList();
  }

  public setDiscipline(discipline: SuggestorType) {
    this.setDisciplineFromSuggestorType(discipline);
    this.clearDisciplineList();
    this.getClassesList();
  }

  private setInvalidDate(isInvalid: boolean) {
    this.invalidDate = isInvalid;
  }

  public updateChanges({ data }: DTable) {
    this.tableData.data = data;
    this.setClasses(data as Class[]);
  }

  public updateAttachment({
    files,
    newFiles,
  }: {
    files: FileUpload[];
    newFiles: File[];
  }) {
    this.setFiles(files);
    this.setNewFiles(newFiles);
  }

  public saveObject(publish = false) {
    this.setPublish(publish);

    const modalOptions: [string, string, string, VueConstructor][] = [
      ['Salvando objeto...', 'SALVAR', 'CANCELAR', EditConfirm],
      ['Publicando objeto de networking...', 'PUBLICAR', 'AINDA NÃO', PublishConfirm],
      ['Salvando rascunho de objeto de networking...', 'SIM', 'NÃO', SaveDraftConfirm],
    ];

    let optionIndex = this.editMode ? 0 : 1;
    if (!publish) optionIndex = 2;

    const [phrase, allowText, denyText, component] = modalOptions[optionIndex];

    this.setLoadingPhrase(phrase);
    const data: DataConfirmation = this.confirmData(allowText, denyText, component);

    setTimeout(() => {
      this.openModal(ModalTipos.CONFIRMACAO, data);
    });
  }

  public async publishRequest() {
    const obj = { ...this.getObject };

    this.saveAssignmentObject(obj)
      .then(({ publish }: AssignmentObjectModel) => {
        if (publish) {
          this.$router.push({ name: ASSIGNMENT_OBJECT_MANAGE });
        }
        if (this.goingBack) this.routerGoBack();
      })
      // TODO: implementar um método próprio para tratar erros
      .catch((err: AxiosError<string>) => {
        this.window.alert(err);
        this.$router.go(0);
      })
      .finally(() => {
        ModalService.fecharModal();
      });
  }

  private routerGoBack() {
    this.$router.back();
  }

  public beforeLeavePage() {
    if (this.hasChange) {
      this.goingBack = true;
      this.setLoadingPhrase('Salvando rascunho de objeto de networking...');
      const data: DataConfirmation = this.confirmData(
        'SALVAR RASCUNHO',
        'DESCARTAR',
        LeavePageConfirm,
      );

      setTimeout(() => {
        this.openModal(ModalTipos.CONFIRMACAO, data, true);
      });
    } else {
      this.routerGoBack();
    }
  }

  public whenModalResponseIsDeny() {
    if (this.goingBack) this.routerGoBack();
  }
}
